@font-face {
font-family: '__avenirRoman_d00b8a';
src: url(/_next/static/media/64133209eed33ccd-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__avenirRoman_Fallback_d00b8a';src: local("Arial");ascent-override: 91.34%;descent-override: 23.62%;line-gap-override: 0.00%;size-adjust: 101.59%
}.__className_d00b8a {font-family: '__avenirRoman_d00b8a', '__avenirRoman_Fallback_d00b8a'
}

@font-face {
font-family: '__avenirBlack_2d3a52';
src: url(/_next/static/media/6c728da6a512bac2-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__avenirBlack_Fallback_2d3a52';src: local("Arial");ascent-override: 92.46%;descent-override: 33.84%;line-gap-override: 0.00%;size-adjust: 108.16%
}.__className_2d3a52 {font-family: '__avenirBlack_2d3a52', '__avenirBlack_Fallback_2d3a52'
}

